import "./index.scss";

const BussinessDesc = (props) => {
  const { data } = props || {};
  return (
    <div className="bussiness-desc">
      <p className="desc-title">
        
        珠海市佳创兴精密机械有限公司
      </p>
      <div className="desc-content">
        <p>
          <span className="big line"></span>珠海市佳创兴精密机械有限公司
          成立于2005年7月29日，公司位于广东省珠海市斗门区新青科技园，厂房总面积九千多平方米，办公楼约1千多平方米，是一家颇具规模、集压缩机研发、五金零配件加工的企业，公司总投资达7000多万元人民币。
        </p>
        <p>
          公司与珠海格力凌达压缩机有限公司长期合作生产加工，空调压缩机配件：
          <span className="line"></span>气缸、法兰、曲轴，五金零件加工。
        </p>
        <p>
          公司于2022年起与国内大型知名通用设备制造企业广东凯特精密机械有限公司成为战略合作伙伴，加工生产各类通用机械设备中的零部件。
        </p>
        <p>
          公司注重技术创新、工艺创新、人才培养，多年来已培养出一大批技术人才和拥有技术解决方案，使公司生产的压缩机配件达到先进水平。
        </p>
        <p>
          公司拥有数控车床、CNC四轴加工中心、16轴雕铣机、铣床、钻床、平面磨床等一百多台设备，有承接大小批量五金零配件的加工能力和丰富的经验。
        </p>
        <p>公司大事记： </p>
        <span className="period">
        <p>2008年 通过ISO9001:2008国际认证。 </p>
        <p>2010年 珠海凌达压缩机有限公司颁发最佳协作奖。 </p>
        <p>2011年 获得珠海市企业家协会理事单位。 </p>
        <p>2012年 荣获珠海市经济发展促进会优秀企业称号。 </p>
        <p>2013年 荣获珠海市经济发展促进会最具社会责任先进单位称号。 </p>
        <p>
          2014年 成为国家安全生产监督管理总局的安全生产标准化三级企业(机械)。{" "}
        </p>
        <p>2016年 获得广东省科技厅颁发的高新技术企业证书。 </p>
        <p>
          2018年
          珠海市科技创新局联合珠海市发展、和改革局同意组建，成为珠海市工程技术研究开发中心。
        </p>
        <p>
          2020年
          获得珠海市斗门区工商业联合会颁发斗门区民营企业调查点工作中先进单位证书。
        </p>
        <p>
          2021年
          珠海市第1版发布的人才引进入户重点企业目录中，公司入选斗门区266家重点企业名单，可以为优秀员工直接办理人才引进，落户珠海市。
        </p>
        <p>
          2022年
          获得珠海市斗门区工商业联合会民营企业调查工作先进单位。
        </p>
        </span>
        <p>
          根据市场需求，公司于2011年4月成立
          <span className="line"></span>珠海市世科机械有限公司，2018年3月在湖北省成立了
          <span className="line"></span>湖北佳创兴精密机械有限公司。
        </p>
        <p>
          公司宗旨“注重质量，精益求精，培养人才，为社会和国家做贡献”，并以“精益求精”的精神发展、壮大！
        </p>
      </div>
    </div>
  );
};
export default BussinessDesc;
